<template>
  <div class="cloneContainer">
    <div class="clone">
      <Skeletor class="image" />
    </div>
    <div class="bottom">
      <Skeletor class="text" />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';

import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    Skeletor
  },

  props: {},

  setup(props) {}
};
</script>
<style scoped lang="scss">
.cloneContainer {
  height: 100%;
  border-radius: 10px;
  transition: all 100ms ease-in;
  &:hover {
    box-shadow: 0px 0px 15px #ffd915;
  }
  &:active {
    box-shadow: 0px 0px 25px #ffd915;
    transform: scale(0.97);
  }

  .clone {
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: all 100ms ease-in;
    cursor: pointer;
    flex: 1;
    border: 5px solid #ffd915;

    min-width: 250px;
    max-width: 300px;
    .image {
      border-radius: 5px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      width: 100%;
      height: 290px;
      width: 290px;
      margin-bottom: -3px;
    }
  }

  .bottom {
    padding: 15px 10px;
    background-color: #ffd915;
    min-width: 250px;
    max-width: 300px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .text {
      font-size: 12px;
      word-break: break-all;
      width: 100%;
      font-size: 16px;
      color: black;
      font-weight: 700;
      height: 25px;
    }
  }
}
</style>
