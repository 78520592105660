<template>
  <Ethereum :callback="initialize" />
  <div v-if="!isConnected" style="margin-top:100px;">
    <ConnectWallet />
  </div>
  <div v-else-if="isLoading" class="skeleton">
    <MintSkeleton />
  </div>

  <div v-else class="mint">
    <div class="heading">Mint Your NFT</div>
    <div class="imageContainer">
      <img src="" />
    </div>
    <p class="text">{{ quantityRemaining }} Remaining</p>
    <!-- <p class="text">Eligible: {{ isWhitelisted }}</p>

    isLive: {{ isLive }}<br />
    quantityRemaining: {{ quantityRemaining }}<br />
    isWhitelisted: {{ isWhitelisted }}<br />
    isConnected: {{ isConnected }}<br />-->
    <div v-if="quantityRemaining > 0">
      <div v-if="!isLive">
        <div v-if="isWhitelisted">
          <div class="dropdownWrapper">
            <div class="tabs">
              <TabItem
                v-for="(time, index) in presaleTimes"
                :key="index"
                :title="'Whitelist #' + time.id"
                :isSelected="currentTab === time.id"
                @click="currentTab = time.id"
              />
            </div>

            <div v-for="(time, index) in presaleTimes" :key="index">
              <div
                class="description"
                v-if="currentTab === time.id"
              >{{ whitelistDescriptions[index] }}</div>
            </div>

            <div
              class="mintWrapper"
              v-for="(time, index) in presaleTimes"
              :key="index"
            >
              <div v-if="currentTab === time.id">
                <div
                  v-if="time.startTime > now"
                  class="mintCountdownWhitelist"
                >
                  <p class="text mintCountdownWhitelistText">Minting Beginning In:</p>

                  <Countdown :startDate="time.startTime" :endDate="time.endTime"></Countdown>
                </div>
                <div class="dropdown" v-else-if="mintQtyOptions[time.id].length > 0">
                  <DropdownMenu
                    class="dropdown"
                    :selection="mintQtySelection[time.id]"
                    :items="mintQtyOptions[time.id]"
                    @selected="handleSelection(index, $event, time.id)"
                  />
                  <Button @click="purchaseItem(time.id)">Mint</Button>
                </div>
                <div v-else-if="mintQtyOptions[time.id].length == 0">
                  <div class="allocation">Allocation Filled</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="!isWhitelisted"
          class="mintCountdown"
        >
          <p class="text mintCountdownText">Minting Beginning In:</p>
          <Countdown
            :startDate="store.state.mint.startDate"
            :endDate="store.state.mint.startDate + 1000000"
          ></Countdown>
        </div>
      </div>
      <div class="dropdownWrapper" v-else-if="isLive">
        <div class="filled dropdown">
          <DropdownMenu
            class="dropdown"
            :selection="mintQtySelection[1]"
            :items="publicAllowances"
            @selected="handleSelection(index, $event, 1)"
          />
          <Button @click="publicPurchaseItem(1)">Mint</Button>
        </div>
      </div>
    </div>

    <div v-else-if="quantityRemaining == 0">
      <p class="text">Sold Out!</p>
    </div>
  </div>
</template>
<script>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { log } from '/src/utility';

// Component imports.
import DropdownMenu from '/src/components/ui/DropdownMenu.vue';
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import Countdown from '/src/components/ui/Countdown.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';
import TabItem from '/src/components/ui/TabItem.vue';
import MintSkeleton from './components/MintSkeleton.vue';
import config from '/src/config'

export default {
  components: {
    DropdownMenu,
    Button,
    Ethereum,
    Countdown,
    ConnectWallet,
    TabItem,
    MintSkeleton
  },

  setup() {
    const publicAllowances = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const store = useStore();

    const handleSelection = (index, selection, whiteListId) => {
      //whiteListId = selection;
      log.info('mintQtySelection.value', selection, mintQtySelection.value, whiteListId);
      mintQtySelection.value[whiteListId] = selection;
    };
    const handleWLSelection = (index, selection) => {
      whiteListId.value = selection;
    };

    onMounted(() => {
      window.scroll(0, 0);
    });

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    let now = ref(new Date());
    setInterval(() => {
      now.value = new Date();
    }, 1000);

    const isLive = computed(() => {
      return store.getters['mint/startDate'] < now.value;
    });

    const mintQtySelection = ref([]);
    const currentTab = ref(1);
    const isLoading = ref(true);

    // const presaleTimes = computed(() => {
    //   // let times = [];
    //   // for()
    //   return store.state.mint.presaleTimes;
    // });
    // const purchaseItem = async () => {
    //   await store.dispatch(
    //     'mint/purchaseItem',
    //     {
    //       poolId: 0,
    //       groupId: 1,
    //       assetId: 0,
    //       amount: mintQtySelection.value
    //     },
    //     { root: true }
    //   );
    // };
    const publicPurchaseItem = async id => {
      await store.dispatch(
        'mint/publicPurchaseItem',
        {
          poolId: 0,
          groupId: 1,
          amount: mintQtySelection.value[id]
        },
        { root: true }
      );
      //
      // await store.dispatch(
      //   'mint/getMerkleData',
      //   { groupId: 1 },
      //   { root: true }
      // );
      await store.dispatch('mint/getShopItems', store.state.mint.shopAddress, {
        root: true
      });
    };

    const purchaseItem = async id => {
      log.info('mintQtySelection.value[whiteListId.value]', mintQtySelection.value[id]);
      await store.dispatch(
        'mint/whitelistedPurchaseItem',
        {
          whitelistId: id,
          poolId: 0,
          groupId: 1,
          amount: mintQtySelection.value[id]
        },
        { root: true }
      );

      await store.dispatch(
        'mint/getMerkleData',
        { groupId: 1 },
        { root: true }
      );
      await store.dispatch('mint/getShopItems', store.state.mint.shopAddress, {
        root: true
      });
    };

    const initialize = async () => {
      // Retrieve the number of NFTs from the store.
      log.info('initialize');
      isLoading.value = true;

      if (store.state.ethers.canSign) {
        await store.dispatch(
          'mint/getShopItems',
          store.state.mint.shopAddress,
          {
            root: true
          }
        );

        await store.dispatch(
          'mint/getMerkleData',
          { groupId: 1 },
          { root: true }
        );

        let item = store.state.mint.shop.pools[0].items[0];

        quantityRemaining.value = item.cap.toNumber() - item.minted.toNumber() - config.reservedMintCount;
      }

      // Poll remaining NFTs from the store.
      setInterval(() => {
        if (store.state.ethers.canSign) {
          store.dispatch('mint/getMerkleData', { groupId: 1 }, { root: true });
          presaleTimes.value = store.state.mint.presaleTimes;
          allowances.value = store.state.mint.allowances;
        }
        // log.info('interval', presaleTimes)
      }, 15000);
      presaleTimes.value = store.state.mint.presaleTimes;
      let idx = 99;
      for (let tm of presaleTimes.value) {
        if (tm.id < idx) {
          idx = tm.id
        }
      }
      currentTab.value = idx;
      allowances.value = store.state.mint.allowances;

      let initAmounts = [];
      for (let time of store.state.mint.presaleTimes) {
        //let time = store.state.mint.presaleTimes[i]
        initAmounts[time.id] = 1;
      }
      mintQtySelection.value = initAmounts;

      isWhitelisted.value = whitelists.value.length > 0;

      isLoading.value = false;

      let amounts = [];
      //log.info("pre length", store.state.mint.presaleTimes.length);
      for (let i = 0; i < store.state.mint.presaleTimes.length; i++) {
        // let id = store.state.mint.presaleTimes[i].id
        // log.info("computed numbers", id);
        let times = Object.values(store.state.mint.presaleTimes);

        let allocations = [];
        let allos = Object.values(store.state.mint.allowances);
        for (let n = 1; n <= allos[i]; n++) {
          allocations.push(n);
        }
        amounts[times[i].id] = allocations;
      }
      mintQtyOptions.value = amounts;
    };

    watch(
      () => store.state.ethers.canSign,
      selection => {
        let canSign = store.state.ethers.canSign;
        if (canSign) {
          initialize();
        } else {
          // clearInterval(listener.value);
        }
      }
    );

    // const numbers = computed(() => {
    //
    // });
    const mintQtyOptions = ref([]);
    const allowances = ref([]);
    const presaleTimes = ref([]);
    const whiteListId = ref(0);
    //const whitelists = ref([]);
    const whitelists = computed(() => {
      return Object.keys(store.state.mint.allowances);
    });

    const isWhitelisted = computed(() => {
      return whitelists.value.length > 0;
      //return store.state.mint.merkleProofs.length > 0;
    });

    const quantityRemaining = computed(() => {
      if (store.state.mint.shop) {
        let item = store.state.mint.shop.pools[0].items[0];
        return item.cap.toNumber() - item.minted.toNumber() - config.reservedMintCount;
      }
      return -1;
    });

    const whitelistDescriptions = config.lang.whitelistDescriptions 

    return {
      allowances,
      isConnected,
      isLive,
      isWhitelisted,
      mintQtySelection,
      mintQtyOptions,
      handleSelection,
      handleWLSelection,
      initialize,
      presaleTimes,
      purchaseItem,
      publicPurchaseItem,
      quantityRemaining,
      store,
      now,
      whiteListId,
      currentTab,
      whitelists,
      isLoading,
      publicAllowances,
      whitelistDescriptions
    };
  }
};
</script>
<style scoped lang="scss">
.skeleton {
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mint {
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  .mintWrapper {
    margin-bottom: 20px;
    margin-top: 20px;

    .filled {
      border: 1px solid #777;
      border-radius: 5px;
      padding: 15px;
    }
    .allocation {
      text-align: center;
      padding: 40px;
      font-size: 16px;
      line-height: 1em;
      font-weight: 700;
      border: 2px solid white;
      border-radius: 5px;
    }
  }

  .description {
    padding-top: 20px;
    opacity: 0.8;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    max-width: 420px;
    line-height: 1.4em;
  }

  .tabs {
    display: flex;
    justify-content: center;
  }

  .dropdownWrapper {
    .dropdown {
      display: grid;
      grid-template-columns: auto 1fr;
      margin-right: 10px;

      button {
        cursor: pointer;
        padding: 5px 20px;
        border-radius: 10px;
        height: 40px;
        -webkit-transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #000;
        background-color: #ffd915;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 3px;
        text-decoration: none;
        text-transform: uppercase;
        border: none;
      }

      button:hover {
        box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -2px) scale(1.05);
        -ms-transform: translate(0px, -2px) scale(1.05);
        transform: translate(0px, -2px) scale(1.05);
        color: #000;
      }

      button:active {
        box-shadow: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }

      .text {
        width: 100%;
        text-align: center;
      }
    }
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .text {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
  }

  .imageContainer {
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 2px solid white;
    }
  }

  .mintCountdownWhitelist {
    border:1px solid #777; 
    border-radius:10px;
    padding:15px;
  }
  .mintCountdownWhitelistText {
    text-align: center;
  }

  .mintCountdown {
    border: 1px solid white;
    border-radius: 10px;
    padding: 15px;
  }
  .mintCountdownText {
    text-align: center;
  }
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>
