'use strict';

import config from '/src/config';
// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  // startDate: new Date(1626897600 * 1000),
  shopAddress: config.shopAddress['0x1'],
  shop: null,
  bucks: [],
  ownsBuck: false,
  merkleUserIndex: '',
  merkleClaimedStatus: true,
  merkleProofs: [],
  allowances: [],
  presaleTimes: [],
  startDate: new Date(Date.UTC(2022, 0, 28, 21, 0))
};

const getters = {
  startDate(state) {
    let timeStamp = state?.shop?.pools?.[0]?.startTime.toNumber();

    // Make fallback 10 days in the future until we get
    // actual time from the contract
    let fallBack = new Date();
    fallBack.setDate(fallBack.getDate() + 10);

    return !timeStamp ? fallBack : new Date(timeStamp * 1000);
  }
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
