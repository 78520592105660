'use strict';

// Imports.
import initializeMerkleconfig from '../initialize-merkleconfig';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import config from '/src/config';
import {log} from '/src/utility'

let checkClaim = async function (groupId, index) {
  if (!index) {
    return true;
  }
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let merklePreSaleAddress = config.merklePreSaleAddress[networkId];
  let merklePreSaleContract = new ethers.Contract(
    merklePreSaleAddress,
    config.merklePreSaleABI,
    signer
  );
  let purchasedStatus = await merklePreSaleContract.isPurchased(groupId, index);

  //return purchasedStatus;
};

let getMerkleData = async function (whitelistId) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let userData;
  let merkleconfig = await initializeMerkleconfig();

  let proofList = merkleconfig.trees[whitelistId].leaves;

  for (let listEntry of proofList) {
    if (listEntry.address.toLowerCase() == address.toLowerCase()) {
      userData = listEntry;
    }
  }

  let merkleData = {
    userProofs: userData ? userData.proof : [],
    index: userData ? userData.index : null,
    allowance: userData ? userData.allowance : 0
  };

  return merkleData;
};

let getMultiMerkleData = async function () {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let merkleData = [];
  let merkleconfig = await initializeMerkleconfig();

  for (let t = 0; t < merkleconfig.trees.length; t++) {
    let tree = merkleconfig.trees[t];
    for (let leaf of tree.leaves) {
      if (leaf.address.toLowerCase() == address.toLowerCase()) {
        let remainder = await getRemaining(leaf, t);
        let leafData = {
          whitelistId: t,
          userProofs: leaf.proof,
          index: leaf.index,
          allowance: leaf.allowance,
          remaining: remainder
        };
        merkleData.push(leafData);
      }
    }
  }
  log.info('fetch tree', merkleData);
  return merkleData;
};

const getRemaining = async function (leaf, whitelistId) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    signer
  );

  let usernode = ethers.utils.solidityKeccak256(
    ['uint256', 'address', 'uint256'],
    [leaf.index, address.toLowerCase(), leaf.allowance]
  );

  let whiteListInput = {
    whiteListId: whitelistId,
    index: leaf.index,
    allowance: leaf.allowance,
    node: usernode,
    merkleProof: leaf.proof
  };
  log.info('whiteListInput', whiteListInput);
  let remainder = await shopContract.remainder(whiteListInput, 0, address);
  return remainder.toString();
};

const getTimes = async function () {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );
  let merkleconfig = await initializeMerkleconfig();
  let times = [];
  for (let t = 0; t < merkleconfig.trees.length; t++) {
    let tree = merkleconfig.trees[t];
    for (let leaf of tree.leaves) {
      if (leaf.address.toLowerCase() == address.toLowerCase()) {
        let rootData = await shopContract.accessRoots(t);

        times.push({
          id: t,
          startTime: new Date(rootData.startTime.toString() * 1000),
          endTime: new Date(rootData.endTime.toString() * 1000)
        });
      }
    }
  }

  return times;
};

let whitelistedPurchaseItem = async function (
  whitelistId,
  poolId,
  groupId,
  amount,
  dispatch
) {
  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    signer
  );
  //let price = await shopContract.price();
  let pools = await shopContract.getPools([poolId], 0);
  //let price = await pools[poolId].items[0].prices[0].price;
  // get price from accessRoots
  let rootData = await shopContract.accessRoots(whitelistId);
  let price = rootData.price;

  let totalSpend = price.mul(amount);

  let wldata = await getMerkleData(whitelistId);
  //let wldata = merkleData[whitelistId];

  let usernode = ethers.utils.solidityKeccak256(
    ['uint256', 'address', 'uint256'],
    [wldata.index, address.toLowerCase(), wldata.allowance]
  );

  let whiteListInput = {
    whiteListId: whitelistId,
    index: wldata.index,
    allowance: wldata.allowance,
    node: usernode,
    merkleProof: wldata.userProofs
  };

  // let check = await shopContract.isEligible(whiteListInput, 0);
  // log.info("eligible", check);

  let redemption = await shopContract.connect(signer).mintFromPool(
    poolId, //poolId
    1, //groupId
    0, //assetIndex (index in price array)
    amount,
    0, //item index in mintshop
    whiteListInput,
    { value: totalSpend }
  );

  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: redemption.hash
      },
      duration: 300000
    },
    { root: true }
  );
  await redemption.wait();

  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Confirmed',
      duration: 10000
    },
    { root: true }
  );

  // await dispatch('mint/getBucks', '', { root: true });
  // await dispatch('windows/close', { label: '' }, { root: true });
  // setTimeout(async () => {
  //   await dispatch('windows/open', { label: 'Member' }, { root: true });
  // }, 2000);
};

// Export the merkle service functions.
export const merkleService = {
  checkClaim,
  getMerkleData,
  getMultiMerkleData,
  getTimes,
  whitelistedPurchaseItem
};
